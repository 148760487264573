import React, {useState} from "react";
import {processParam} from "../shared";
import InquiryForm from "../inquiry/inquiry-form";
const EnquiryComponent = ({}:{}) => {
    // const [params, _] = useState<any>(processParam({defaultValue:``}));
    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_categories.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container">
                        <div className="header-icons"><span></span><span></span><span></span></div>
                        <div className="page-head__title"> Générer votre devis</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content">
            <div className="uk-section-large uk-container">
                <InquiryForm/>
            </div>
        </div>
    </>
}

export default EnquiryComponent;