import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {post} from "../../services/BaseService";
import {appUrls} from "../../util/api_constant";
declare let UIkit: any;
const InquiryForm = ({}:{}) => {
    const {register, handleSubmit, setValue, formState: {errors}} = useForm<any>({mode: 'all'});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [transport, setTransport] = useState<"YES" | "NO">("YES");

    const onSubmit = (data: any) => {
        setIsSubmitting(true);
        data['transport'] = (transport === "YES");
        post(appUrls.inquiry, data)
            .then(() => {
                if (UIkit){
                    UIkit.notification({message: `<span uk-icon='icon: check'></span> Félicitation ! <br> Votre demande a été envoyée avec succès`,  status: 'success'});
                }
                setIsSubmitted(true);
                if (typeof window !== "undefined")
                    window.scroll(0, 0);

                setValue('fullName', undefined);
                setValue('email', undefined);
                setValue('phoneNumber', undefined);
                setValue('destination', undefined);
                setValue('equipment', undefined);
                setValue('notes', undefined);
                setValue('transport', undefined);
                setValue('serviceType', undefined);
                setValue('endDate', undefined);
                setValue('startDate', undefined);
                setValue('billingType', undefined);
                setIsSubmitting(false);
            }).catch(() => {
            setIsSubmitting(false);
            if (UIkit){
                UIkit.notification({message: `<span uk-icon='icon: close'></span> Ooops!. <br> Queque chose s'est mal passé. Vérifiez les données saisies et réessayez ultérieurement !`,  status: 'danger'});
            }
        })
    }

    return <>
        {
            isSubmitted ?
                <div className="uk-text-center">
                    <div data-uk-alert={"true"} className="uk-margin uk-background-primary uk-card uk-card-default uk-card-body">
                        <h2>Félicitation !</h2>
                        <p>Nous avons bien reçu votre demande. Nous vous revienderons très vite.</p>
                        <div className="uk-align-center">
                            <a href="/" className="uk-button uk-button-small">
                                <span>Aller à la page d'accueil</span>
                            </a>
                        </div>
                    </div>
                </div> :
        <div className="block-form">
            <div className="section-title">
                <div className="uk-h2">Demande de devis</div>
            </div>
            <div className="section-content">
                <p>Nous avons besoin de ces informations pour vous genérer votre devis. Tous les champs marqués <span style={{color:'red'}}>*</span> sont obligatoires.</p>
                <form onSubmit={handleSubmit(onSubmit)} className={"uk-form-stacked"}>
                    <div className="uk-grid uk-grid-small uk-child-width-1-2@s" data-uk-grid="true">
                        <div>
                            <label className="uk-form-label">Nom complet <span style={{color:'red'}}>*</span></label>
                            <input {...register(`fullName`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`fullName`] ? 'uk-form-danger' : ''}`}
                                   type="text"
                                    placeholder="Nom complet *"/>
                        </div>
                        <div>
                            <label className="uk-form-label">Email</label>
                            <input {...register(`email`,{required: false})}
                                    className={`uk-input uk-form-controls uk-form-large ${errors[`email`] ? 'uk-form-danger' : ''}`}
                                    type="email"
                                    placeholder="Email "/>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Numéro de téléphone <span style={{color:'red'}}>*</span></label>
                            <input {...register(`phoneNumber`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`phoneNumber`] ? 'uk-form-danger' : ''}`}
                                   type="text"
                                   placeholder="Numéro de téléphone *"/>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Lieu de destination <span style={{color:'red'}}>*</span></label>
                            <input {...register(`destination`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`destination`] ? 'uk-form-danger' : ''}`}
                                   type="text"
                                   placeholder="Destination des équipements *"/>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Date(prevue) de début <span style={{color:'red'}}>*</span></label>
                            <input {...register(`startDate`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`startDate`] ? 'uk-form-danger' : ''}`}
                                   type="date"
                                   placeholder="Date(prevue) de début *"/>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Date(prevue) de fin <span style={{color:'red'}}>*</span></label>
                            <input {...register(`endDate`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`endDate`] ? 'uk-form-danger' : ''}`}
                                   type="date"
                                   placeholder="Date(prevue) de fin"/>
                        </div>

                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Type de travaux <span style={{color:'red'}}>*</span></label>
                            <select {...register(`serviceType`,{required: true})}
                                    className={`uk-select uk-form-controls uk-form-large ${errors[`serviceType`] ? 'uk-form-danger' : ''}`}
                                    >
                                <option value={"REHABILITATION"}>Rehabilitation</option>
                                <option value={"MINE"}>Travaux Miniers</option>
                                <option value={"TP"}>Bâtiment et Travaux Publics</option>
                                <option value={"TRANSPORT"}>Transport</option>
                            </select>
                        </div>
                        <div className="uk-width-1-2">
                            <label className="uk-form-label">Choix de facturation <span style={{color:'red'}}>*</span></label>
                            <select {...register(`billingType`,{required: true})}
                                    className={`uk-select uk-form-controls uk-form-large ${errors[`billingType`] ? 'uk-form-danger' : ''}`}
                            >
                                <option value={"HOURLY"}>Par Heure</option>
                                <option value={"DAILY"}>Par Jour</option>
                                <option value={"TOTAL"}>Par Production</option>
                                <option value={"COUNTER"}>Par Compteur</option>
                            </select>
                        </div>
                        <div className="uk-width-1-1">
                            <label className="uk-form-label">Type d'équipement <span style={{color:'red'}}>*</span></label>
                            <input {...register(`equipment`,{required: true})}
                                   className={`uk-input uk-form-controls uk-form-large ${errors[`equipment`] ? 'uk-form-danger' : ''}`}
                                   type="text"
                                   placeholder="Type d'équipement *"/>
                        </div>
                        <div className="uk-margin">
                            <div className="uk-form-label">Voulez-vous que SME Sarl transporte l'équipement pour vous  <span style={{color:'red'}}>*</span>?</div>
                            <div className="uk-form-controls">
                                <label><input className="uk-radio" type="radio" name="radio1" checked={(transport === "YES")} onChange={() => {setTransport('YES')}} value={"YES"} /> Oui - SME Sarl peut le tranporter</label><br/>
                                <label><input className="uk-radio" type="radio" name="radio1" checked={(transport === "NO")} onChange={() => {setTransport('NO')}} value={"NO"}/> Non - je m'en occupe</label>
                            </div>
                        </div>

                        <div className="uk-width-1-1">
                            <label className="uk-form-label">Plus d'informations</label>
                            <textarea
                                {...register(`notes`,{required: false})}
                                className={`uk-textarea uk-form-controls uk-form-large ${errors[`notes`] ? 'uk-form-danger' : ''}`}
                                placeholder="Plus de precisions sur votre demande"/>
                        </div>
                        <div>
                            <button disabled={isSubmitting} className="uk-button uk-button-large" type="submit">
                                {isSubmitting?<span><div data-uk-spinner="ratio: 2"/>Envoi en cours ...</span>:
                                    <span>Envoyer la demande</span>}
                                {
                                    (!isSubmitting)?<img src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg="true"/>:null
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        }
    </>
}

export default InquiryForm;