import React, {useEffect} from "react";
import Layout from "../components/layout/Layout";
import {loadScript} from "../util/helper";
import EnquiryComponent from "../components/equipments/enquiryComponent";
const Enquiry = () => {
    useEffect(() => {
        loadScript("/assets/js/libs.js");
        setTimeout(() => {
            loadScript("/assets/js/main.js");
        })
    })
    return <>
    <Layout title={'Demande de devis'}>
        <EnquiryComponent/>
    </Layout>
    </>
}
export default Enquiry;